@import "../abstracts/variables";
@import "../abstracts/mixins";

.container {
  margin: 0 auto;
  padding: 0 50px;

  @include md-and-down {
    padding: 0 24px;
  }
}

.h1 {
  font-size: 44px;
  font-weight: 500;
  line-height: 1.14;
  text-transform: uppercase;
  @include xs {
    font-size: 24px;
  }
}

.h2 {
  font-size: 30px;
  line-height: 1.27;
  text-transform: uppercase;
  @include xs {
    font-size: 20px;
  }
}

.h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  @include xs {
    font-size: 16px;
  }
}

.text {
  font-size: 16px;
  line-height: 1.5;
}

.button-text {
  font-size: 14px;
  line-height: 1.43;
  text-transform: uppercase;
}

.for-desktop {
  @include md-and-down{
    display: none !important;
  }
}

.for-tablet {
  @include rwd-up($screen-lg-min){
    display: none !important;
  }
  @include rwd($screen-sm-min - 1){
    display: none !important;
  }
}
.for-mobile {
  @include rwd-up($screen-sm-min){
    display: none !important;
  }
}
