@import "../abstracts/variables";
@import "../abstracts/mixins";

.haval-hub__header {
  position: fixed;
  z-index: $z-index-3;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
  padding-top: 20px;

  color: $main-white-color;

  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 1));

  @include xs {
    display: none;
  }

  @include rwd(835px) {
    .button-text {
      font-size: 12px;
    }
  }

  &-item {
    margin: 0 10px 10px;

    cursor: pointer;
    transition: color $tr-dur;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include hover {
      color: $accent-color;
    }
  }
}
