
.haval-hub__button {
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 17px 38px 8px 21px;

  line-height: 1;

  background: url("../../assets/img/icons/button-bg.svg") no-repeat center center / auto auto;
}
