@import "../abstracts/variables";
@import "../abstracts/mixins";

.haval-hub__manifest {
  position: relative;

  &-bg {
    width: 100%;
    img {
      display: block;

      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }

  &-video {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-index-1;

    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  &-content {
    position: absolute;
    top: 66vh;
    left: 50%;
    z-index: $z-index-2;

    width: 100%;

    text-align: center;
    color: $main-white-color;

    transform: translate(-50%, -50%);
    transition: opacity $tr-dur;

    .h1 {
      margin-bottom: 20px;

      color: $accent-color;
    }


    &.hidden {
      opacity: 0;

      pointer-events: none;
    }
  }

  &-play {
    margin-bottom: 46px;

    cursor: pointer;
    transition: filter $tr-dur;

    @include hover {
      filter: invert(1);
    }
  }

  &-text {
    padding-top: 102px;
    padding-bottom: 70px;

    color: $main-white-color;

    background-color: $main-black-color;

    .h1 {
      color: $accent-color;
    }

    &-row {
      margin-top: 50px;

      column-count: 2;
      column-gap: 6.2%;
    }

    p {
      margin-bottom: 30px;
    }

    span {
      color: $accent-color;
    }
  }

  @include lg-and-down {
    &-content {
      top: 59vh;
    }
    &-text {
      padding-bottom: 93px;
      &-row {
        height: 442px;
      }
    }
  }

  @include md-and-down {
    &-content {
      top: 62vh;

      width: 90%;
    }

    &-text {
      padding-top: 50px;
      padding-bottom: 50px;

      &-row {
        height: auto;

        column-count: 1;
      }
    }
  }
  @include xs {
    &-content {
      top: 59.7vh;
    }

    &-play {
      margin-bottom: 20px;
    }

    &-content {
      width: 87%;
    }
  }
}
