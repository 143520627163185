// Fonts
$main-font-family: Haval, Arial, sans-serif;
$main-f-z: 18px;
$standard-f-z: 16px;
$small-f-z: 14px;

$main-f-z-new: 14px;
$standard-f-z-new: 12px;
$small-f-z-new: 10px;

// colors
$main-black-color: #121314;
$main-white-color: #ffffff;
$accent-color: #00C9BF;

// grid

// background


// features

// transitions
$tr-dur: 0.2s;
$tr-dur-l: 0.4s;

// z-index
$z-index-1: 1;
$z-index-2: 10;
$z-index-3: 100;
$z-index-4: 1000;
$z-index-5: 10000;
$z-index-10: 1000000000;
