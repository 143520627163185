@import "../abstracts/variables";
@import "../abstracts/mixins";

.haval-hub__media {
  display: flex;

  &-img {
    min-width: 61.6%;
    object-fit: cover;
  }

  &-text {
    padding-top: 95px;
    padding-bottom: 95px;

    .h1 {
      margin-bottom: 50px;
    }

    .h3 {
      margin-bottom: 50px;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  @include lg-and-down {
    &-img {
      width: 41.6%;
      min-width: 41.6%;
    }

    &-text {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  @include md-and-down {
    flex-direction: column !important;

    &-img {
      width: 100%;
    }

    &-text {
      padding-top: 50px;
      padding-bottom: 50px;

      .h3 {
        margin-bottom: 48px;
      }
    }
  }
}
